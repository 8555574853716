<template>
  <div>
    <trev-cool-table
      :headers="headers"
      :courier="$courier.ReportSQL"
      title="SQL Reports"
      :addNewLink="{ name: 'Report Add' }"
      tableId="reportsql"
    >
      <template v-slot:item.actions="{ item }">
        <v-btn
          :to="{ name: 'Report View', params: { reportId: item.id } }"
          small
          color="primary"
        >
          <v-icon class="mr-2"> mdi-magnify </v-icon>
          View
        </v-btn>
        <v-btn
          :to="{ name: 'Report Add', params: { reportId: item.id } }"
          class="ml-2"
          small
          color="warning"
        >
          <v-icon class="mr-2"> mdi-pencil </v-icon>
          Edit
        </v-btn>
        <v-btn color="danger" class="ml-2" small @click="deleteReport(item)">
          <v-icon class="mr-2"> mdi-delete </v-icon>
          Delete
        </v-btn>
      </template>
    </trev-cool-table>
    <trev-confirm-delete-dialog
      :itemToDelete="itemToDelete.name"
      :maxWidth="600"
      :show.sync="isShowDelete"
      @dialog-delete-deleted="deletedItem"
      @dialog-delete-cancelled="deleteCancel"
    >
    </trev-confirm-delete-dialog>
  </div>
</template>

<script>
import TrevConfirmDeleteDialog from "../../base/dialog/trev-confirm-delete-dialog.vue";
import trevCoolTable from "../../tables/trev-cool-table.vue";
export default {
  components: { trevCoolTable, TrevConfirmDeleteDialog },
  methods: {
    deleteReport(item) {
      this.itemToDelete = item;
      this.isShowDelete = true;
    },
    async deletedItem() {        
      await this.$courier.ReportSQL.removeById(this.itemToDelete.id);
      this.$eventBus.$emit('reportsql-refresh');
      this.deleteCancel();
    },
    deleteCancel() {
      this.itemToDelete = {};
      this.isShowDelete = false;
    },
  },
  data() {
    return {
      isShowDelete: false,
      itemToDelete: {},
      headers: [
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Actions",
          value: "actions",
        },
      ],
    };
  },
};
</script>

<style>
</style>