<template>
  <div>
    <v-card>
      <v-toolbar>
        <v-toolbar-title>
          All Reporting
          <v-progress-circular
            indeterminate
            v-if="isLoading"
          ></v-progress-circular>
        </v-toolbar-title>
        <v-spacer> </v-spacer>
        <v-expand-transition>
          <span v-if="search && !searching">
            Search:
            <v-chip close class="ml-1" @click:close="search = ''">
              {{ search }}
            </v-chip>
          </span>
        </v-expand-transition>
        <v-expand-transition>
          <span style="width: 350px" v-show="searching">
            <v-text-field
              v-model="search"
              label="Search"
              single-line
              hide-details
              class="align-center"
              ref="tblSearch"
              @blur="onSearchBlur"
              @keyup.esc="onSearchBlur"
            >
            </v-text-field>
          </span>
        </v-expand-transition>
        <v-btn icon @click="clickedSearchButton">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
        <v-btn icon :to="{ name: 'Report Add' }">
          <v-icon> mdi-plus </v-icon>
        </v-btn>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon>
              <v-icon> mdi-dots-vertical </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item-group>
              <v-list-item @click="getData()">
                <v-list-item-content> Refresh Data </v-list-item-content>
                <v-list-item-action>
                  <v-icon> mdi-refresh </v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </v-toolbar>
      <v-list>
        <v-subheader v-show="staticReports.length > 0"> Reports </v-subheader>
        <v-list-item-group>
          <v-list-item v-for="(item, index) in staticReports" :key="index">
            <v-list-item-title>
              {{ item.name }}
              <span class="float-right">
                <v-btn color="primary" :to="item.link" small>
                  <v-icon class="mr-2"> mdi-magnify </v-icon>
                  View
                </v-btn>
              </span>
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
        <v-subheader v-show="sqlReports.length > 0"> SQL Reports </v-subheader>
        <v-list-item-group>
          <v-list-item v-for="(item, index) in sqlReports" :key="index">
            <v-list-item-title>
              {{ item.name }}
              <span class="float-right">
                <v-btn
                  color="danger"
                  class="mr-2"
                  small
                  @click="deleteReport(item)"
                >
                  <v-icon class="mr-2"> mdi-delete </v-icon>
                  Delete
                </v-btn>
                <v-btn
                  :to="{ name: 'Report Add', params: { reportId: item.id } }"
                  class="mr-2"
                  small
                  color="warning"
                >
                  <v-icon class="mr-2"> mdi-pencil </v-icon>
                  Edit
                </v-btn>
                <v-btn
                  :to="{ name: 'Report View', params: { reportId: item.id } }"
                  small
                  color="primary"
                >
                  <v-icon class="mr-2"> mdi-magnify </v-icon>
                  View
                </v-btn>
              </span>
            </v-list-item-title>
          </v-list-item>
          <v-list-item inactive :ripple="false">
            <v-list-item-content>
              <v-pagination
                v-model="pageNumber"
                :length="numberOfPages"
              ></v-pagination>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <v-list-item
          v-if="staticReports.length === 0 && sqlReports.length == 0"
        >
          <v-list-item-title class="text-center">
            <span class="grey--text lighten-4"
              >Couldn't find any reports with: </span
            ><span class="info--text">{{ search }}</span>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-card>
    <trev-confirm-delete-dialog
      :itemToDelete="itemToDelete.name"
      maxWidth="600px"
      :show.sync="isShowDelete"
      @dialog-delete-deleted="deletedItem"
      @dialog-delete-cancelled="deleteCancel"
    >
    </trev-confirm-delete-dialog>
    <!-- <v-card>
      <v-toolbar>
        <v-toolbar-title>
          Static Reports
        </v-toolbar-title>
      </v-toolbar>
      <v-list>
        <v-list-item>
          <v-list-item-title>
            All Company Support Time Usages
          </v-list-item-title>
          <v-list-item-action>
            <v-btn :to="{ name: 'Support Time Usage' }">
              <v-icon class="mr-2">
                mdi-magnify
              </v-icon>
              View
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card>
    <trev-report-list class="mt-4"> </trev-report-list> -->
  </div>
</template>

<script>
import TrevConfirmDeleteDialog from "../../../../components/base/dialog/trev-confirm-delete-dialog.vue";
import trevReportList from "../../../../components/reports/sql/trev-report-list.vue";
export default {
  components: { trevReportList, TrevConfirmDeleteDialog },
  computed: {
    skipAmount() {
      return (this.pageNumber - 1) * this.pageSize;
    },
    numberOfPages() {
      return Math.ceil(this.pageSize / this.pageSize);
    },
  },
  watch: {
    search(newValue, oldValue) {
      clearTimeout(this.debounceId);

      this.debounceId = setTimeout(() => {
        this.getData();

        this.staticReports = this.staticReportsRaw
          .filter((x) =>
            x.name.toLowerCase().includes(this.search.toLowerCase())
          )
          .slice(this.skipAmount, this.skipAmount + this.pageSize);
      }, 200);
    },
    pageNumber(newValue, oldValue) {
      clearTimeout(this.debounceId);

      this.debounceId = setTimeout(() => {
        this.getData();

        this.staticReports = this.staticReportsRaw
          .filter((x) =>
            x.name.toLowerCase().includes(this.search.toLowerCase())
          )
          .slice(this.skipAmount, this.skipAmount + this.pageSize);
      }, 200);
    },
  },
  created() {
    this.staticReportsRaw = require('../../../../data/staticReportsList').default;
    this.getData();

    this.staticReports = this.staticReportsRaw;
  },
  methods: {
    deleteReport(item) {
      this.itemToDelete = item;
      this.isShowDelete = true;
    },
    async deletedItem() {
      await this.$courier.ReportSQL.removeById(this.itemToDelete.id);
      this.getData();
      this.deleteCancel();
    },
    deleteCancel() {
      this.itemToDelete = {};
      this.isShowDelete = false;
    },
    onSearchBlur() {
      this.searching = false;
    },
    clickedSearchButton() {
      this.searching = !this.searching;
      this.$nextTick(() => this.$refs.tblSearch.focus());
    },
    getData() {
      this.isLoading = true;
      this.$courier.ReportSQL.getData(
        this.pageNumber,
        this.pageSize,
        this.search
      ).then((response) => {
        this.sqlReports = response.payload;
        this.totalSQLReportsCount = response.totalCount;
        this.isLoading = false;
      });
    },
  },
  data() {
    return {
      isShowDelete: false,
      itemToDelete: {},
      debounceId: "",
      isLoading: false,
      search: "",
      totalSQLReportsCount: 0,
      pageNumber: 1,
      pageSize: 15,
      searching: false,
      sqlReports: [],
      staticReports: [],
      staticReportsRaw: [],
    };
  },
};
</script>

<style>
</style>