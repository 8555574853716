export default [
    {
        name: "Ticket Reports By Product",
        link: {
            name: "Support Time Usage",
        },
        hideInBar: true
    },
    {
        name: 'Jobs - All',
        link: {
            name: 'Global Jobs'
        }
    },
    {
        name: 'Jobs - Open not invoiced',
        link: {
            name: 'Open Not Invoiced Jobs'
        }
    },
    {
        name: 'Jobs - Complete not invoiced',
        link: {
            name: 'Completed Not Invoiced Jobs'
        }
    },
    {
        name: 'Products - Microsoft',
        link: {
            name: 'Microsoft Product Report'
        }
    }
]